import './App.css';
import SignInCard from './pages/SignInPage';
import SignUpPage from './pages/SignUpPage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ApolloClient, InMemoryCache, ApolloProvider, HttpLink, from } from '@apollo/react-hooks';
import Navbar from './components/Navbar';
import { checkUser } from './utils/userLogin';
import HomePage from './pages/HomePage';
import TradesPage from './pages/TradesPage';
import BattlePage from './pages/BattlePage';
import TreePage from './pages/TreePage';
import TreeView from './pages/TreeView';
import GoogleCallback from './pages/GoogleCallback';

const link = from([
  new HttpLink({ uri: 'https://api.cs302nasilemak.xyz/graphql' }),
]);

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: link,
});

function App() {
  const isLoggedIn = (() => {
    try {
      return checkUser();
    } catch (error) {
      console.error('Authentication error:', error);
      return false;
    }
  })();

  return (
    <>
      <ApolloProvider client={client}>
      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/signup" element={<SignUpPage />} />
          <Route path="/signin" element={<SignInCard />} />
          <Route path="/" element={isLoggedIn ? <HomePage /> : <SignInCard />} />
          <Route path="/tree" element={isLoggedIn ? <TreeView /> : <SignInCard />} />
          <Route path="/trades" element={isLoggedIn ? <TradesPage /> : <SignInCard />} />
          <Route path="/battles" element={isLoggedIn ? <BattlePage /> : <SignInCard />} />
          <Route path="/google-callback" element={<GoogleCallback />} />
          {/* <Route path="/tree" element={isLoggedIn ? <HomePage /> : <SignInCard />} /> */}
        </Routes>
      </BrowserRouter>
      </ApolloProvider>
    </>
  );
}

export default App;