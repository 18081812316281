import { gql } from '@apollo/client';

export const SIGNUP = gql`
    mutation Mutation($username: String!, $email: String!, $password: String!) {
        signup(username: $username, email: $email, password: $password) {
            token
            user {
                user_id
                username
                email
            }
        }
    }
`
export const LOGIN = gql`
    mutation Mutation($username: String!, $password: String!) {
        login(username: $username, password: $password) {
            token
            user {
                user_id
            }
        }
    }
`
export const GET_USER_DETAILS_BY_ID = gql`
    query Query($userId: Int!) {
        user(user_id: $userId) {
            user_id
            username
            email
        }
    }
`

export const JOIN_GROUP = gql`
    mutation Mutation($groupId: Int!, $userId: Int!) {
        joinGroup(group_id: $groupId, user_id: $userId) {
            members {
                username
            }
        }
    }
`
export const GET_GROUPS = gql`
    query UserGroup {
        groups {
            group_id
            name
            creator_id
            members {
                username
            }
        }
    }
`
export const GET_GROUPMATES = gql`
    query Query($userId: Int!) {
        userGroup(user_id: $userId) {
            members {
                user_id
                username
            }
        }
    }
`