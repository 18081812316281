import { gql } from '@apollo/client';

export const GET_ACHIEVEMENTS = gql`
    query Query($achievementId: Int!) {
        achievement(id: $achievementId) {
            achievement_id
            description
            name
            points
        }
    }
`

export const CREATE_USER_TREE = gql`
    mutation Mutation($userId: Int!) {
        createUserTree(userId: $userId) {
            user_id
            tree_level {
                level_name
                required_growth
            }
            current_growth
            current_level_id
        }
    }
`

export const GET_USER_TREE = gql`
    query Query($userId: Int!) {
        userTree(userId: $userId) {
            user_id
            tree_level {
                required_growth
                level_name
            }
            current_growth
            current_level_id
        }
    }
`