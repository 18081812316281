import { useQuery, useMutation } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GET_USER_DETAILS_BY_ID } from '../graphql/UserSchemas';
import { UPDATE_TRADE_STATUS } from '../graphql/TradeSchemas';
import { getUserId } from '../utils/userLogin';
import { Card, CardContent, Typography, Avatar, Box, Chip, capitalize, Button } from '@mui/material';

const TradeCard = ({ trade }) => {
  const { data: reqUserData, error: reqError, loading: reqLoading } = useQuery(GET_USER_DETAILS_BY_ID, {
    variables: { userId: trade.trade_status === 'approved' ? trade.trade_receiver_id : trade.user_id },
  });

  const { data: recUserData, error: recError, loading: recLoading } = useQuery(GET_USER_DETAILS_BY_ID, {
    variables: { userId: trade.trade_status === 'approved' ? trade.user_id : trade.trade_receiver_id },
  });
  const [reqUser, setReqUser] = useState('')
  const [recUser, setRecUser] = useState('')
  const [updateTradeStatus, { updateTradeStatusLoading }] = useMutation(UPDATE_TRADE_STATUS);

  useEffect(() => {
    if (reqUserData && reqUserData.user) {
      setReqUser(reqUserData.user);
    }
  }, [reqUserData])

  useEffect(() => {
    if (recUserData && recUserData.user) {
      setRecUser(recUserData.user);
    }
  }, [recUserData])

  const handleAcceptTrade = async () => {
    const response = await updateTradeStatus({
      variables: {
        taskId: trade.task_id,
        title: trade.title,
        status: trade.status,
        tradeStatus: 'approved',
        description: trade.description,
        tradeReceiverId: trade.user_id,
        userId: trade.trade_receiver_id
  
      }
    });
    console.log(response);
    window.location.reload();
  };

  return (
    <Card sx={{ backgroundColor: '#f5f5f5' }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="button" align="center" gutterBottom sx={{ mx: 4, mb: 1 }}>Requestor</Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1">
                {reqUser?.username || 'N/A'}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column" alignItems="center">
            <Typography variant="button" align="center" gutterBottom sx={{ mx: 4, mb: 1 }}>Recipient</Typography>
            <Box display="flex" alignItems="center">
              <Typography variant="subtitle1">
                {recUser?.username || 'N/A'}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Typography variant="h6" align="center">
          {trade.title}
        </Typography>
        <Box display="flex" justifyContent="center" mt={1}>
          <Chip
            label={trade.trade_status}
            color={
              trade.trade_status === 'pending' ? 'warning' :
                trade.trade_status === 'approved' ? 'success' :
                  trade.trade_status === 'rejected' ? 'error' : 'default'
            }
            variant='outlined'
            size="medium"
            sx={{ fontWeight: 'bold' }}
          />
        </Box>
        <Button
          onClick={handleAcceptTrade}
          sx={{ borderRadius: 10, width: 340, mt: 1, backgroundColor: "#1E4759", color: "#ffffff" }}
          variant="contained"
          color="primary"
          disabled={(trade.trade_receiver_id !== parseInt(getUserId()) && trade.trade_status === 'pending')
            || trade.trade_status === 'approved' || trade.trade_status === 'rejected'
          }
        >
          Accept Trade Request
        </Button>
      </CardContent>
    </Card>
  );
};
export default TradeCard;
