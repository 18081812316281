import { useQuery, useMutation } from '@apollo/client';
import { GET_GOOGLE_AUTH_URL, CONNECT_GOOGLE_CALENDAR, SYNC_TASK_TO_CALENDAR } from '../graphql/CalendarSchemas';
import { getUserId } from '../utils/userLogin';

const CalendarConnect = () => {
  const [syncTask, {loading}] = useMutation(SYNC_TASK_TO_CALENDAR);

  const syncTaskToGoogleCalendar = async (taskId) => {
    const response = await syncTask({
      variables: {
        taskId: taskId,
        userId: parseInt(getUserId())
      }
    });
    console.log(response);
  };

  return { syncTaskToGoogleCalendar };
};

export default CalendarConnect;
