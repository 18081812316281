import { useEffect } from 'react';
import { gql } from '@apollo/client';
import { useApolloClient } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { CONNECT_GOOGLE_CALENDAR } from '../graphql/CalendarSchemas';
import { getUserId } from '../utils/userLogin';

const GoogleCallback = () => {
  const client = useApolloClient();
  const location = useLocation();
  const [connectCalendar, {loading: calendarConnectLoading}] = useMutation(CONNECT_GOOGLE_CALENDAR);
  const params = new URLSearchParams(location.search);
  const code = params.get('code');
  
  const handleGoogleCallback = async () => {
    if (code) {
      try {
        const response = await connectCalendar({
          variables: {
            userId: parseInt(getUserId()),
            code: String(code)
          }
        });
        localStorage.setItem('calendarLink', code);
        localStorage.setItem('calendarConnected', 'true');
        window.close();
        
      } catch (error) {
        console.error('Error connecting calendar:', error);
      }
    }
  }
  
  useEffect(() => {
    handleGoogleCallback();
  }, []);
  
  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   const code = params.get('code');
    
  //   if (code) {
  //     try {
  //       const response = connectCalendar({
  //         variables: {
  //           userId: parseInt(getUserId()),
  //           code: String(code)
  //         }
  //       });
  //       console.log(response.data?.connectGoogleCalendar?.message);
  //       sessionStorage.setItem('calendarLink', code);
  //       sessionStorage.setItem('calendarConnected', 'true');
  //       console.log('Calendar connected successfully');
  //       window.close();
        
  //     } catch (error) {
  //       console.error('Error connecting calendar:', error);
  //     }
  //   }
  // }, []);

  return (
    <div>
      Connecting to Google Calendar...
    </div>
  );
};

export default GoogleCallback;
