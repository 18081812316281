import { gql } from 'apollo-boost';

export const GET_TASKS = gql`
    query Query($userId: Int!) {
        userTasks(user_id: $userId) {
            task_id
            title
            description
            due_date
            reminder_time
            status
            trade_status
        }
    }
`
export const COMPLETE_TASK = gql`
    mutation Mutation($taskId: Int!) {
        completeTask(task_id: $taskId) {
            message
        }
    }
`
export const GET_TASK_BY_ID = gql`
    query Query($taskId: Int!) {
        task(task_id: $taskId) {
            task_id
            title
            status
            trade_status
            description
        }
    }
`
export const CREATE_TASK = gql`
    mutation Mutation($userId: Int!, $title: String!, $dueDate: String!, $status: String!, $description: String, $tradeStatus: String, $tradeReceiverId: Int, $reminderTime: String) {
        createTask(user_id: $userId, title: $title, due_date: $dueDate, status: $status, description: $description, trade_status: $tradeStatus, trade_receiver_id: $tradeReceiverId, reminder_time: $reminderTime) {
            task_id
            title
        }
    }
`
