import React, { useState, useEffect, useRef } from 'react';
import './TreeView.css';
import treeLevel1 from '../assets/tree-level-1.png';
import treeLevel2 from '../assets/tree-level-2.png';
import treeLevel3 from '../assets/tree-level-3.png';
import soilImage from '../assets/soil.png';
import { useQuery } from '@apollo/react-hooks';
import { getUserId } from '../utils/userLogin';
import { GET_USER_TREE } from '../graphql/AchievementSchemas';

const TreeView = () => {
  const [visibleLevels, setVisibleLevels] = useState([]);
  const [currentPoints, setCurrentPoints] = useState(0);
  const [progress, setProgress] = useState(0);
  const [userTree, setUserTree] = useState(null);
  const [currentLevel, setCurrentLevel] = useState(1);
  const {error, loading, data } = useQuery(GET_USER_TREE, {
    variables: { userId: parseInt(getUserId()) }
  });

  useEffect(() => {
    if (data && data.userTree) {
      setUserTree(data.userTree);
      setCurrentPoints(data.userTree.current_growth);
      setCurrentLevel(data.userTree.current_level_id);
      //setVisibleLevels([data.userTree.current_level_id, data.userTree.current_level_id + 1]);
      console.log('User Tree Data:', data);
    }
  }, [data]);

  const treeLevels = [
    { image: treeLevel1 },
    { image: treeLevel2 },
    { image: treeLevel3 },
  ];

  const pointsForNextLevel = 1000;

  const calculateProgress = () => {
    const targetProgress = (currentPoints % pointsForNextLevel / pointsForNextLevel) * 100;
    setProgress(targetProgress);
  };

  useEffect(() => {
    calculateProgress();
  }, [currentPoints]);

  useEffect(() => {
    if (data?.userTree?.current_level_id) {
      const currentLevel = data.userTree.current_level_id;
      const levelImages = treeLevels.slice(0, currentLevel);
      setVisibleLevels(levelImages);
    }
  }, [userTree]);

  return (
    <>
      <div className="tree-container">
        {visibleLevels.map((level, index) => (
          <div
            key={index}
            className="tree-level"
            style={{ bottom: `${index * 20}px` }}
          >
            <img src={level.image} alt={`Tree Level ${index + 1}`} />
          </div>
        ))}
      </div>
      <div className="soil-level">
        <img src={soilImage} alt="Soil" />
        <div className="progress-container">
          <div className="level-indicator left">Level {userTree?.current_level_id || 1}</div>
          <div className="progress-bar" style={{ width: `${progress}%` }}></div>
          <div className="progress-text">
            {currentPoints % 1000 || 0}/1000 points
          </div>
          <div className="level-indicator right">Level {(userTree?.current_level_id || 1) + 1}</div>
        </div>
        <div className="points-needed-text">
          {1000 - (currentPoints % 1000 || 0)} more points needed for next level
        </div>
      </div>
    </>
  );
};

export default TreeView;
