import React, { useState, useEffect } from 'react';
import { Card, CardContent, Chip, Typography, Avatar, Box, IconButton, Button, capitalize } from '@mui/material';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import CreateBattleTasksModal from './CreateBattleTasksModal';
import crown from '../assets/king.png';
import loser from '../assets/loser.png';
import initiator from '../assets/initiator.png';
import opponent from '../assets/opponent.png';
import ViewBattleDetailsModal from './ViewBattleDetailsModal';
import { ADD_BATTLE_TASK, GET_BATTLE_TASKS, ACCEPT_BATTLE_REQUEST } from '../graphql/BattleSchemas';
import { useQuery, useMutation } from '@apollo/client';
import { getUserId } from '../utils/userLogin';

const BattleCard = ({ battle }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [addBattleTask, { addBattleTaskLoading }] = useMutation(ADD_BATTLE_TASK);
  const { error, loading, data } = useQuery(GET_BATTLE_TASKS, {
    variables: { battleId: battle.id, userId: parseInt(getUserId()) }
  });
  const [acceptBattleRequest, { acceptBattleRequestloading }] = useMutation(ACCEPT_BATTLE_REQUEST);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const handleViewOpen = () => setViewModalOpen(true);
  const handleViewClose = () => setViewModalOpen(false);

  useEffect(() => {
    if (data && data.battleTasks) {
      setTasks(data.battleTasks);
    }
  }, [data]);
  
  const handleCreate = async (opponent, taskRows) => {
    for (const task of taskRows) {
      const response = await addBattleTask({
        variables: {
          battleId: battle.id,
          name: task.name,
          description: task.description,
          duration: parseInt(task.duration),
          userId: parseInt(getUserId())
        }
      });
      console.log(response);
    }
  };

  const handleAccept = async () => {
    const response = await acceptBattleRequest({
      variables: {
        battleId: battle.id,
      }
    });
    console.log(response);
    window.location.reload();
  };

  const isBattleExpired = () => {
    const startTime = new Date(battle.start_time);
    const currentTime = new Date();
    return currentTime > startTime;
  };

  const getDisplayStatus = () => {
    if (battle.status === 'pending' && isBattleExpired()) {
      return 'expired';
    }
    return battle.status;
  };

  const haveWinner = (battle.winner === null || battle.winner === 0);
  const winnerName = (battle.winner === battle.initiator_id ? battle.initiator.username : battle.opponent.username);
  const initiatorTitle = (haveWinner ? null :
    (battle.winner === battle.initiator_id) ? "Winner" : "Loser");
  const opponentTitle = (haveWinner ? null :
    (battle.winner === battle.initiator_id) ? "Loser" : "Winner");
  const initiatorImage = (haveWinner ? initiator :
    (battle.winner === battle.initiator_id) ? crown : loser);
  const opponentImage = (haveWinner ? opponent :
    (battle.winner === battle.initiator_id) ? loser : crown);

  return (
    <>
      <Card sx={{ backgroundColor: "#f5f5f5", minWidth: 275, mb: 2, mr: 1, position: 'relative' }}>
        <IconButton
          onClick={handleViewOpen}
          sx={{
            position: 'absolute',
            top: 4,
            right: 3,
            zIndex: 2,
          }}
        >
          <OpenWithIcon sx={{ mx: -0.5, my: -0.5, transform: 'rotate(45deg)', color: '#1E4759' }} />
        </IconButton>
        <CardContent>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2} mt={2}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Avatar alt={initiatorTitle} src={initiatorImage} sx={{ width: 80, height: 80 }} variant='square' />
              <Typography variant="h6" mt={1}>
                {battle.initiator.username}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Points: {battle.initiator_score}
              </Typography>
            </Box>
            <Typography variant="h5" component="span">
              VS
            </Typography>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Avatar alt={opponentTitle} src={opponentImage} sx={{ width: 80, height: 80 }} variant='square' />
              <Typography variant="h6" mt={1}>
                {battle.opponent.username}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Points: {battle.opponent_score}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" justifyContent="center" mt={1}>
            <Chip
              label={capitalize(getDisplayStatus())}
              color={
                getDisplayStatus() === 'pending' ? 'warning' :
                getDisplayStatus() === 'active' ? 'secondary' :
                getDisplayStatus() === 'completed' ? 'success' :
                getDisplayStatus() === 'expired' ? 'error' : 'default'
              }
              variant="outlined"
              size="medium"
              sx={{ fontWeight: 'bold' }}
            />
          </Box>
          <Box display="flex" justifyContent="center" mt={1}>
            {battle.opponent_id === parseInt(getUserId()) && 
             battle.status === "pending" && 
             !isBattleExpired() ? (
              <Button
                onClick={handleAccept}
                sx={{ borderRadius: 10, width: 300, mt: 1, backgroundColor: "#1E4759", color: "#ffffff" }}
                variant="contained"
                color="primary">
                Accept Battle
              </Button>
            ) : false}
          </Box>
          <Box display="flex" justifyContent="center" mt={1}>
            {battle.status === "active" && (
              <Button
                onClick={handleOpen}
                sx={{ borderRadius: 10, width: 300, mt: 1, backgroundColor: "#1E4759", color: "#ffffff" }}
                variant="contained"
                color="primary">
                Add Battle Tasks
              </Button>
            )}
          </Box>
        </CardContent>
      </Card>
      <ViewBattleDetailsModal open={viewModalOpen} handleClose={handleViewClose} battle={battle} tasks={tasks} />
      <CreateBattleTasksModal
        open={modalOpen}
        handleClose={handleClose}
        handleCreate={handleCreate}
      />
    </>
  );
};

export default BattleCard;