import React from 'react';
import { Modal, Box, Typography, Button, Avatar, Chip, Divider } from '@mui/material';
import crown from '../assets/king.png';
import loser from '../assets/loser.png';
import initiator from '../assets/initiator.png';
import opponent from '../assets/opponent.png';
import { useMutation } from '@apollo/react-hooks';
import { COMPLETE_BATTLE_TASK } from '../graphql/BattleSchemas';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 900,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    maxHeight: '90vh',
    overflow: 'auto'
};

const ViewBattleDetailsModal = ({ open, handleClose, battle, tasks }) => {
    const [markComplete, { markCompleteLoading }] = useMutation(COMPLETE_BATTLE_TASK);
    const haveWinner = (battle.winner === null || battle.winner === 0);
    const winnerName = (battle.winner === battle.initiator_id ? battle.initiator.username : battle.opponent.username);
    const initiatorImage = (haveWinner ? initiator : 
      (battle.winner === battle.initiator_id) ? crown : loser);
    const opponentImage = (haveWinner ? opponent : 
      (battle.winner === battle.initiator_id) ? loser : crown);
  
    const handleMarkComplete = async (taskId) => {
        // Placeholder for API call
        const response = await markComplete({ variables: { taskId: taskId } });
        console.log(`Mark task ${taskId} as complete`);
        window.location.reload();
        open = true;
    };
    
    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h5" component="h2">
                        View Battle Details
                    </Typography>
                    <Button onClick={handleClose} sx={{ minWidth: 'auto', p: 0 }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </Button>
                </Box>
                <Divider sx={{ mb: 3 }} />
                {/* Battle Header */}
                <Box display="flex" justifyContent="space-between" justifySelf="center" alignItems="center" mb={4} width={500}>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Avatar src={initiatorImage} sx={{ width: 80, height: 80 }} variant='square' />
                        <Typography variant="h6" mt={1}>
                            {battle.initiator.username}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            Points: {battle.initiator_score}
                        </Typography>
                    </Box>
                    <Typography variant="h5" component="span">
                        VS
                    </Typography>
                    <Box display="flex" flexDirection="column" alignItems="center">
                        <Avatar src={opponentImage} sx={{ width: 80, height: 80 }} variant='square' />
                        <Typography variant="h6" mt={1}>
                            {battle.opponent.username}
                        </Typography>
                        <Typography variant="body1" color="text.secondary">
                            Points: {battle.opponent_score}
                        </Typography>
                    </Box>
                </Box>

                <Box display="flex" justifyContent="center" mb={3}>
                    <Chip
                        label={battle.status}
                        color="success"
                        variant="outlined"
                        size="large"
                        sx={{ fontWeight: 'bold' }}
                    />
                </Box>

                <Divider sx={{ mb: 3 }} />

                {/* Battle Tasks List */}
                <Typography variant="h6" mb={2}>
                    Battle Tasks
                </Typography>
                {tasks.map((task, index) => (
                    <Box
                        key={index}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                            p: 2,
                            mb: 1,
                            backgroundColor: '#f5f5f5',
                            borderRadius: 1
                        }}
                    >
                        <Box sx={{ flex: 1 }}>
                            <Typography variant="subtitle1" fontWeight="bold">
                                {task.name}
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {task.description}
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <Typography variant="body2">
                                {task.duration} hours
                            </Typography>
                            <Button
                                variant="contained"
                                color="success"
                                size="small"
                                onClick={() => handleMarkComplete(task.id)}
                                disabled={task.completed}
                            >
                                {task.completed ? 'Completed' : 'Mark Complete'}
                            </Button>
                        </Box>
                    </Box>
                ))}
            </Box>
        </Modal>
    );
};

export default ViewBattleDetailsModal;