import React, { useEffect, useState } from 'react'
import { Box, Container, Stack, Typography, Button } from '@mui/material'
import BattleCard from '../components/BattleCard'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RequestBattleModal from '../components/RequestBattleModal';
import { useQuery } from '@apollo/client';
import { GET_BATTLE_HISTORY, GET_BATTLE_REQUESTS } from '../graphql/BattleSchemas';
import { getUserId } from '../utils/userLogin';

const BattlePage = () => {
    const [battleRequests, setBattleRequests] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [battles, setBattles] = useState([])

    const handleOpenModal = () => setOpenModal(true)
    const handleCloseModal = () => setOpenModal(false)
    
    const { error: battleError, loading: battlesLoading, data: battlesData } = useQuery(GET_BATTLE_REQUESTS, {
        variables: { userId: parseInt(getUserId()) }
    })

    const { error, loading, data } = useQuery(GET_BATTLE_HISTORY, {
        variables: { userId: parseInt(getUserId()) }
    })


    // const handleCreateBattle = (newBattle) => {
        
    // }
    useEffect(() => {
        // Placeholder for API call
        if(battlesData && battlesData.battleRequests) {
            console.log(battlesData)
            setBattleRequests(battlesData.battleRequests) 
        }
    },[battlesData])

    useEffect(() => {
        // Placeholder for API call
        if(data && data.battleHistory) {
            console.log(data)
            setBattles(data.battleHistory) 
        }
    },[data])
    
    return (
        <Container maxWidth="lg" sx={{ mt: 4 }}>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Battles
                </Typography>
                <Button variant='contained' sx={{ borderRadius: 15, backgroundColor: "#1E4759" }} onClick={handleOpenModal}>
                    <AddCircleOutlineIcon sx={{ marginRight: 1 }} />
                    Send Battle Request
                </Button>
                <RequestBattleModal open={openModal} handleClose={handleCloseModal}/>
            </Box>
            <Typography variant="button" component="h2" gutterBottom sx={{ mb: 2, mx: .5 }}>
                Your Requests
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                {battleRequests.map((battle) => (
                    <Box key={battle.id} sx={{ width: 'calc(33.33% - 16px)' }}>
                        <BattleCard battle={battle} />
                    </Box>
                ))}
            </Box>
            <Typography variant="button" component="h2" gutterBottom sx={{ mb: 2, mx: .5 }}>
                Your Battles
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                {battles.map((battle) => (
                    <Box key={battle.id} sx={{ width: 'calc(33.33% - 16px)' }}>
                        <BattleCard battle={battle} />
                    </Box>
                ))}
            </Box>
        </Container>
    )
}

export default BattlePage
