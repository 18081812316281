import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, Autocomplete, IconButton, duration } from '@mui/material';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useQuery } from '@apollo/react-hooks';
import { GET_GROUPMATES } from '../graphql/UserSchemas';
import { SEND_BATTLE_REQUEST } from '../graphql/BattleSchemas';
import { useMutation } from '@apollo/react-hooks';
import { getUserId } from '../utils/userLogin';


const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

const RequestBattleModal = ({ open, handleClose }) => {
    const formRef = React.useRef(null);
    const [opponent, setOpponent] = useState(null);
    const [users, setUsers] = useState([]);
    const [startDate, setStartDate] = useState('');
    const [groupMates, setGroupMates] = useState([]);
    const [sendBattleRequest, { sendBattleRequestloading }] = useMutation(SEND_BATTLE_REQUEST);
    const { error, loading, data } = useQuery(GET_GROUPMATES, {
        variables: { userId: parseInt(getUserId()) }
    });

    useEffect(() => {
        if (data && data.userGroup && data.userGroup.members) {
            setGroupMates(data.userGroup.members.filter(member => member.user_id !== parseInt(getUserId())));
            console.log(data);
        }
    }, [data]);


    const formatDateTime = (dateTimeStr) => {
        const date = new Date(dateTimeStr);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(formRef.current);
        console.log({
            initiatorId: parseInt(getUserId()),
            opponentId: opponent.user_id,
            startTime: startDate,
            duration: parseInt(data.get('duration')),
        });
        try {
            const response = await sendBattleRequest({
                variables: {
                    initiatorId: parseInt(getUserId()),
                    opponentId: opponent.user_id,
                    startTime: startDate,
                    duration: parseInt(data.get('duration')),
                }
            });
            console.log(response);
            window.location.reload();
        } catch (error) {
            console.log('Battle request failed: ', error);
        }
        handleClose();
    };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
                    <Typography variant="h6" component="h2">
                        Send Battle Request
                    </Typography>
                    <Button onClick={handleClose} sx={{ minWidth: 'auto', p: 0 }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </Button>
                </Box>
                <form onSubmit={handleSubmit} ref={formRef}>
                    <Autocomplete
                        name="opponent"
                        options={groupMates}
                        getOptionLabel={(option) => option?.username || ''}
                        renderInput={(params) => (
                            <TextField {...params} label="Choose Opponent" margin="normal" />
                        )}
                        value={opponent || null}
                        onChange={(event, newValue) => {
                            setOpponent(newValue);
                        }}
                        sx={{ mt: 3, mb: 2 }}
                    />
                    <Box sx={{ display: 'flex', gap: 2, mt: 2 }}>
                        <TextField
                            size="small"
                            label="Battle Duration (hours)"
                            name='duration'
                            variant="outlined"
                            type="number"
                            InputProps={{ inputProps: { min: 1 } }}
                            sx={{ width: '200px' }}
                        />
                        <TextField
                            size="small"
                            label="Start Date & Time"
                            name='startTime'
                            variant="outlined"
                            type="datetime-local"
                            InputLabelProps={{ shrink: true }}
                            sx={{ width: '286px' }}
                            onChange={(e) => setStartDate(formatDateTime(e.target.value))}
                        />
                    </Box>
                    <Button type="submit" variant="contained" sx={{ mt: 2 }}>
                        Send Battle Request
                    </Button>
                </form>
            </Box>
        </Modal>
    );
};

export default RequestBattleModal;