import React, { useState, useEffect } from 'react';
import { Modal, Box, Typography, TextField, Button, Autocomplete, IconButton } from '@mui/material';
import ControlPointDuplicateIcon from '@mui/icons-material/ControlPointDuplicate';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const CreateBattleTasksModal = ({ open, handleClose, handleCreate }) => {
  const [opponent, setOpponent] = useState(null);
  const [taskRows, setTaskRows] = useState([{ name: '', description: '', duration: '' }]);
  const [users, setUsers] = useState([]);

  const addTaskRow = () => {
    setTaskRows([...taskRows, { name: '', description: '', duration: '' }]);
  };

  const removeTaskRow = (index) => {
    const newTaskRows = taskRows.filter((_, i) => i !== index);
    setTaskRows(newTaskRows);
  };

  const handleTaskChange = (index, field, value) => {
    const newTaskRows = [...taskRows];
    newTaskRows[index][field] = value;
    setTaskRows(newTaskRows);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    handleCreate(opponent, taskRows);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6" component="h2">
            Add Battle Tasks
          </Typography>
          <Button onClick={handleClose} sx={{ minWidth: 'auto', p: 0 }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </Button>
        </Box>
        <form onSubmit={handleSubmit}>
          {taskRows.map((task, index) => (
            <Box key={index} sx={{ mt: 2, p: 1.5, border: '1px solid #e0e0e0', borderRadius: 2, position: 'relative' }}>
              <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                <TextField
                  size="small"
                  label="Task Name"
                  variant="outlined"
                  value={task.name}
                  onChange={(e) => handleTaskChange(index, 'name', e.target.value)}
                  sx={{ flex: 2 }}
                />
                <TextField
                  size="small"
                  label="Description"
                  variant="outlined"
                  value={task.description}
                  onChange={(e) => handleTaskChange(index, 'description', e.target.value)}
                  sx={{ flex: 3 }}
                />
                <TextField
                  size="small"
                  label="Hours"
                  variant="outlined"
                  type="number"
                  InputProps={{ inputProps: { min: 1 } }}
                  value={task.duration}
                  onChange={(e) => handleTaskChange(index, 'duration', e.target.value)}
                  sx={{ width: '100px' }}
                />
                <IconButton
                  onClick={() => removeTaskRow(index)}
                  sx={{
                    color: '#ff4444',
                    '&:hover': { color: '#cc0000' }
                  }}
                >
                  <RemoveCircleOutlineIcon />
                </IconButton>
              </Box>
            </Box>
          ))}
          <Box display={'flex'} justifyContent={'right'} sx={{ mt: 2 }}>
            <Button
              variant='contained'
              onClick={addTaskRow}
              sx={{ borderRadius: 10, backgroundColor: "#4CAF50", color: "#ffffff" }}
            >
              <ControlPointDuplicateIcon sx={{ mr: 1 }} />
              Add Battle Task
            </Button>
          </Box>
          <Button type="submit" variant="contained" sx={{ mt: 2 }} onClick={handleSubmit}>
            Save Battle Tasks
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default CreateBattleTasksModal;