import { gql } from 'apollo-boost';

export const GET_TRADE_TASKS = gql`
    query Query {
        tasks {
            trade_status
            user_id
            title
            task_id
            status
            description
            trade_receiver_id
        }
    }
`
export const GET_TRADE_TASKS_RECEIVER = gql`
    query Query($receiverId: Int!) {
        tradeReceiverTasks(receiver_id: $receiverId) {
            trade_receiver_id
            task_id
            title
            status
            description
            trade_status
            user {
                username
                user_id
            }
            user_id
        }
    }
`
export const UPDATE_TRADE_STATUS = gql`
    mutation Mutation($taskId: Int!, $title: String, $description: String, $status: String, $tradeStatus: String, $tradeReceiverId: Int, $userId: Int) {
        updateTask(task_id: $taskId, title: $title, description: $description, status: $status, trade_status: $tradeStatus, trade_receiver_id: $tradeReceiverId, user_id: $userId) {
            trade_status
        }
    }
`

export const INITIATE_TRADE = gql`
    mutation Mutation($taskId: Int!, $userId: Int!, $receiverId: Int!, $receiverEmail: String!) {
        tradeTask(task_id: $taskId, user_id: $userId, receiver_id: $receiverId, receiver_email: $receiverEmail) {
            message
        }
    }
`
