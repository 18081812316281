import React, { useEffect, useState } from 'react';
import { Modal, Box, Typography, TextField, Button, Avatar, Autocomplete } from '@mui/material';
import { useQuery } from '@apollo/react-hooks';
import { getUserId } from '../utils/userLogin';
import { GET_GROUPMATES, GET_USER_DETAILS_BY_ID } from '../graphql/UserSchemas';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const InitiateTradeModal = ({ open, handleClose, handleAddTrade, tasks }) => {
  const [selectedTask, setSelectedTask] = useState('');
  const [recipient, setRecipient] = useState('');
  const [groupMates, setGroupMates] = useState([]);
  const [recipientEmail, setRecipientEmail] = useState('');

  const { error, loading, data } = useQuery(GET_GROUPMATES, {
    variables: { userId: parseInt(getUserId()) }
  });

  const { data: recipientData } = useQuery(GET_USER_DETAILS_BY_ID, {
    variables: { userId: recipient?.user_id || 0 },
    skip: !recipient
  });

  useEffect(() => {
    if (data && data.userGroup && data.userGroup.members) {
      setGroupMates(data.userGroup.members.filter(member => member.user_id !== parseInt(getUserId())));
      console.log(data);
    }
  }, [data]);

  const handleTaskSelect = (event, newValue) => {
    setSelectedTask(newValue);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
 
    const newTradeRequest = {
      userId: parseInt(getUserId()),
      taskId: selectedTask.task_id,
      receiverId: recipient.user_id,
      receiverEmail: recipientData?.user?.email
    };
    
    handleAddTrade(newTradeRequest);
    handleClose();
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6" component="h2">
            Create New Trade Request
          </Typography>
          <Button onClick={handleClose} sx={{ minWidth: 'auto', p: 0 }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </Button>
        </Box>
        <form onSubmit={handleSubmit}>
          <Autocomplete
            options={tasks}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => <TextField {...params} label="Select Task to Trade" />}
            onChange={handleTaskSelect}
            data-testid="task-title-input"
            sx={{ mt: 2 }}
          />
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
            <Autocomplete
              options={groupMates}
              getOptionLabel={(option) => option?.username || ''}
              renderInput={(params) => (
                <TextField {...params} label="Recipient Name" margin="normal" />
              )}
              value={recipient || null}
              onChange={(event, newValue) => {
                setRecipient(newValue)
              }}
              fullWidth
            />
          </Box>
          <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }} data-testid="initiate-trade-button">
            Initiate Trade
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default InitiateTradeModal;
