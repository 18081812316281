import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import colorLogo from '../assets/logo.png';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { SIGNUP, JOIN_GROUP, GET_GROUPS } from '../graphql/UserSchemas';
import { authenticateUser, addUserId, getUserId, checkUser } from '../utils/userLogin';
import { useNavigate } from 'react-router-dom';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(-8),
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  backgroundColor: '#ffffff',
  gap: theme.spacing(2),
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
}));

const GroupCard = styled(MuiCard)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#f5f5f5',
}));

const SignUpPage = () => {
  const navigate = useNavigate();
  const formRef = React.useRef(null);
  const [step, setStep] = React.useState(1);
  const [formErrors, setFormErrors] = React.useState({
    email: '',
    username: '',
    password: '',
    retypePassword: ''
  });
  const [signUp, { loading }] = useMutation(SIGNUP);
  const [joinGroup, { joinGrploading }] = useMutation(JOIN_GROUP);
  // Replace the static groups array with the API data
  const { error, loading: groupsLoading, data } = useQuery(GET_GROUPS);
  const [groups, setGroups] = React.useState([]);

  React.useEffect(() => {
    if (data && data.groups) {
      console.log('Response: ', data);
      setGroups(data.groups);
    }
  }, [data]);

  const validateForm = (data) => {
    const errors = {};

    if (!data.email || !/\S+@\S+\.\S+/.test(data.email)) {
      errors.email = 'Please enter a valid email address';
    }

    if (!data.username || data.username.length < 3) {
      errors.username = 'Username must be at least 3 characters long';
    }

    if (!data.password || data.password.length < 6) {
      errors.password = 'Password must be at least 6 characters long';
    }

    if (data.password !== data.retypePassword) {
      errors.retypePassword = 'Passwords do not match';
    }

    return errors;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(formRef.current);
    const data = {
      email: formData.get('email'),
      username: formData.get('username'),
      password: formData.get('password'),
      retypePassword: formData.get('retypePassword')
    };

    const errors = validateForm(data);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      try {
        const response = await signUp({
          variables: {
            email: data.email,
            username: data.username,
            password: data.password
          }
        });
        console.log('Sign up successful');
        authenticateUser(response.data.signup.token);
        console.log(response);
        console.log(response.data.signup.user.user_id);
        addUserId(response.data.signup.user.user_id);
        setStep(2);
      } catch (error) {
        console.error('Sign up failed', error);
      }
    }
  };

  const handleGroupJoin = async (groupId) => {
    try {
      console.log('Group ID:', groupId);
      const response = await joinGroup({
        variables: {
          groupId: groupId,
          userId: parseInt(getUserId()) // Replace with actual user ID
        }
      });
      // Navigate to home page after successful group join
      console.log('Joined group successfully');
      navigate('/')
    } catch (error) {
      console.error('Failed to join group', error);
    }
  };

  const handleCreateGroup = () => {
    // Navigate to create group page
  };

  if (step === 1) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
        <Card variant="outlined">
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', gap: 1 }}>
            <img src={colorLogo} alt="Logo" style={{ width: 125, height: 40 }} />
          </Box>
          <Box
            component="form"
            ref={formRef}
            onSubmit={handleSubmit}
            noValidate
            sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}
          >
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <TextField
                error={!!formErrors.email}
                helperText={formErrors.email}
                id="email"
                name="email"
                type="email"
                placeholder="your@email.com"
                autoComplete="email"
                required
                fullWidth
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="username">Username</FormLabel>
              <TextField
                error={!!formErrors.username}
                helperText={formErrors.username}
                id="username"
                name="username"
                placeholder="username"
                required
                fullWidth
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="password">Password</FormLabel>
              <TextField
                error={!!formErrors.password}
                helperText={formErrors.password}
                id="password"
                name="password"
                type="password"
                placeholder="••••••"
                required
                fullWidth
              />
            </FormControl>

            <FormControl>
              <FormLabel htmlFor="retypePassword">Retype Password</FormLabel>
              <TextField
                error={!!formErrors.retypePassword}
                helperText={formErrors.retypePassword}
                id="retypePassword"
                name="retypePassword"
                type="password"
                placeholder="••••••"
                required
                fullWidth
              />
            </FormControl>

            <Button type="submit" fullWidth variant="contained" onClick={handleSubmit}>
              Sign up
            </Button>

            <Typography sx={{ textAlign: 'center' }}>
              Already have an account?{' '}
              <Link href="/signin" variant="body2">
                Sign in
              </Link>
            </Typography>
          </Box>
        </Card>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', height: '100vh' }}>
      <Card variant="outlined">
        <Typography variant="h5" component="h2" gutterBottom>
          Join a Group
        </Typography>
        <Typography variant="body1" color="text.secondary" gutterBottom>
          Choose a group to join or create your own
        </Typography>

        {groups.map(group => (
          <GroupCard key={group.group_id} variant="outlined">
            <Box>
              <Typography variant="h6">
                {group.name}
              </Typography>
            </Box>
            <Button
              variant="contained"
              onClick={() => handleGroupJoin(group.group_id)}
              sx={{ backgroundColor: "#1E4759" }}
            >
              Join
            </Button>
          </GroupCard>
        ))}

        <Divider sx={{ my: 2 }}>or</Divider>

        <Button
          variant="outlined"
          onClick={handleCreateGroup}
          fullWidth
        >
          Create New Group
        </Button>
      </Card>
    </div>
  );
}

export default SignUpPage;