import { gql } from '@apollo/client';
export const GET_GOOGLE_AUTH_URL = gql`
    query Query($userId: Int!) {
        getGoogleAuthUrl(user_id: $userId) {
            auth_url
        }
    }
`;

export const CONNECT_GOOGLE_CALENDAR = gql`
    mutation Mutation($code: String!, $userId: Int!) {
        connectGoogleCalendar(code: $code, user_id: $userId) {
            message
            success
        }
    }
`

export const SYNC_TASK_TO_CALENDAR = gql`
    mutation Mutation($taskId: Int!, $userId: Int!) {
        syncTaskToCalendar(task_id: $taskId, user_id: $userId) {
            message
            success
        }
    }
`