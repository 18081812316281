import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import MuiCard from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

import ForgotPassword from './ForgotPassword';
import colorLogo from '../assets/logo.png';
import { LOGIN } from '../graphql/UserSchemas';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { addUserId, authenticateUser } from '../utils/userLogin';
import { useNavigate } from 'react-router-dom';

const Card = styled(MuiCard)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginTop: theme.spacing(-8),
  alignSelf: 'center',
  width: '100%',
  padding: theme.spacing(4),
  backgroundColor: '#ffffff',
  gap: theme.spacing(2),
  boxShadow:
    'hsla(220, 30%, 5%, 0.05) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.05) 0px 15px 35px -5px',
  [theme.breakpoints.up('sm')]: {
    width: '450px',
  },
  ...theme.applyStyles('dark', {
    boxShadow:
      'hsla(220, 30%, 5%, 0.5) 0px 5px 15px 0px, hsla(220, 25%, 10%, 0.08) 0px 15px 35px -5px',
  }),
}));

export default function SignInCard(props) {
  const formRef = React.useRef(null);
  const navigate = useNavigate();
  const [emailError, setEmailError] = React.useState(false);
  const [emailErrorMessage, setEmailErrorMessage] = React.useState('');
  const [passwordError, setPasswordError] = React.useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [login, { loading }] = useMutation(LOGIN);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = new FormData(formRef.current);
    console.log({
      email: data.get('username'),
      password: data.get('password'),
    });
    try {
      const response = await login({
        variables: {
          username: data.get('username'),
          password: data.get('password')
        }
      });
      console.log(response);
      authenticateUser(response.data.login.token);
      addUserId(response.data.login.user.user_id);
      navigate('/', {replace: true});
      window.location.reload();
    } catch (error) {
      console.log('Login failed: ', error);
    }
  };

  return (
    <div style={{display: 'flex', justifyContent: 'center', height: '100vh'}}>
        <Card variant="outlined">
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', gap: 1 }}>
            <img src={colorLogo} alt="Logo" style={{ width: 125, height: 40 }} />
        </Box>
        <Box
            component="form"
            ref={formRef}
            onSubmit={handleSubmit}
            noValidate
            sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}
        >
            <FormControl>
            <FormLabel htmlFor="username">Username</FormLabel>
            <TextField
                id="username"
                name="username"
                autoFocus
                required
                fullWidth
                variant="outlined"
                color={emailError ? 'error' : 'primary'}
                sx={{ ariaLabel: 'email' }}
            />
            </FormControl>
            <FormControl>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <FormLabel htmlFor="password">Password</FormLabel>
                <Link
                component="button"
                type="button"
                onClick={handleClickOpen}
                variant="body2"
                sx={{ alignSelf: 'baseline' }}
                >
                Forgot your password?
                </Link>
            </Box>
            <TextField
                error={passwordError}
                helperText={passwordErrorMessage}
                name="password"
                placeholder="••••••"
                type="password"
                id="password"
                autoComplete="current-password"
                autoFocus
                required
                fullWidth
                variant="outlined"
                color={passwordError ? 'error' : 'primary'}
            />
            </FormControl>
            <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
            />
            <ForgotPassword open={open} handleClose={handleClose} />
            <Button type="submit" fullWidth variant="contained" onClick={handleSubmit}>
            Sign in
            </Button>
            <Typography sx={{ textAlign: 'center' }}>
            Don&apos;t have an account?{' '}
            <span>
                <Link
                href="/signup"
                variant="body2"
                sx={{ alignSelf: 'center' }}
                >
                Sign up
                </Link>
            </span>
            </Typography>
        </Box>
        </Card>
    </div>
  );
}