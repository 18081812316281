import React, { useEffect } from 'react'
import { useState } from 'react'
import { Box, Container, Typography, Button } from '@mui/material'
import TradeCard from '../components/TradeCard'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import InitiateTradeModal from '../components/InitiateTradeModal';
import { getUserId } from '../utils/userLogin';
import { useQuery, useMutation } from '@apollo/client';
import { GET_TRADE_TASKS, GET_TRADE_TASKS_RECEIVER, INITIATE_TRADE, UPDATE_TRADE_STATUS } from '../graphql/TradeSchemas';
import { GET_USER_DETAILS_BY_ID } from '../graphql/UserSchemas';
import { GET_TASK_BY_ID } from '../graphql/TaskSchemas';

const TradesPage = () => {
    const [trades, setTrades] = useState([])
    const [taskInTrade, setTaskInTrade] = useState('')
    const [tasksToTrade, setTasksToTrade] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [user, setUser] = useState('');
    const [initiateTrade, { createTradeloading }] = useMutation(INITIATE_TRADE);
    const { loading, data } = useQuery(GET_TRADE_TASKS, {
        variables: { userId: parseInt(getUserId()) }
    });
    const [updateTradeStatus, { updateTradeStatusLoading }] = useMutation(UPDATE_TRADE_STATUS);
    const { err, taskLoading, taskData } = useQuery(GET_TASK_BY_ID, {
        variables: { taskId: taskInTrade.taskId }
    });

    useEffect(() => {
        if (data && data.tasks) {
            console.log(data);
            setTasksToTrade(data.tasks.filter(task => ((task.trade_status === 'none' || task.trade_status === 'approved') && task.user_id === parseInt(getUserId()))));
            setTrades(data.tasks.filter(task => (task.trade_status !== 'none'&& 
                (task.user_id === parseInt(getUserId()) || task.trade_receiver_id === parseInt(getUserId())))));
        }
    }, [data])

    const handleAddTrade = async (newTrade) => {
        console.log(newTrade);
        const taskBeingTraded = tasksToTrade.filter(task => task.task_id === newTrade.taskId)[0];
        //console.log("new trade task: ", tasksToTrade.filter(task => task.task_id === newTrade.taskId))
        //console.log(taskInTrade);
        const response = await initiateTrade({
            variables: {
                taskId: newTrade.taskId,
                userId: parseInt(getUserId()),
                receiverId: newTrade.receiverId,
                receiverEmail: newTrade.receiverEmail
            }
        });
        const updateTradeStatusData = await updateTradeStatus({
            variables: {
                taskId: newTrade.taskId,
                title: taskBeingTraded.title,
                status: taskBeingTraded.status,
                tradeStatus: 'pending',
                description: taskBeingTraded.description,
                tradeReceiverId: newTrade.receiverId
            }
        });
        // setTrades([...trades, { id: trades.length + 1, ...newTrade }]);
        window.location.reload();
    };

    return (
        <Container maxWidth="lg" sx={{ mt: 4 }}>
            <Box display={'flex'} justifyContent={'space-between'}>
                <Typography variant="h4" component="h1" gutterBottom>
                    Trades
                </Typography>
                <Button
                    variant='contained'
                    sx={{ borderRadius: 15, backgroundColor: "#1E4759" }}
                    onClick={() => setOpenModal(true)}
                >
                    <AddCircleOutlineIcon sx={{ marginRight: 1 }} />
                    Initiate Trade
                </Button>
                <InitiateTradeModal open={openModal} handleClose={() => setOpenModal(false)} handleAddTrade={handleAddTrade}
                    tasks={tasksToTrade} />
            </Box>
            <Typography variant="button" component="h2" gutterBottom sx={{ mb: 2, mx: .5 }}>
                Your Trades
            </Typography>
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
                {trades.map((trade) => (
                    <Box key={trade.task_id} sx={{ width: 'calc(33.33% - 16px)' }}>
                        <TradeCard trade={trade}/>
                    </Box>
                ))}
            </Box>
        </Container>
    )
}

export default TradesPage
