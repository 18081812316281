function authenticateUser(token) {
    sessionStorage.setItem("token", token);
}

function addUserId(userId) {
  sessionStorage.setItem("userId", userId);
}
  
function getUserId() {
  return sessionStorage.getItem("userId");
}

// If we need logout functionality
function removeUser() {
  sessionStorage.removeItem("user");
}
  
function checkUser() {
  if (sessionStorage.getItem("token")) {
    return true;
  }
  return false;
}
  
export { authenticateUser, addUserId, getUserId, checkUser, removeUser };
  