import { gql } from "apollo-boost";

export const SEND_BATTLE_REQUEST = gql`
    mutation Mutation($initiatorId: Int!, $opponentId: Int!, $startTime: String!, $duration: Int!) {
        sendBattleRequest(initiator_id: $initiatorId, opponent_id: $opponentId, start_time: $startTime, duration: $duration) {
            battle_id
            message
        }
    }
`

export const ACCEPT_BATTLE_REQUEST = gql`
    mutation Mutation($battleId: Int!) {
        acceptBattleRequest(battle_id: $battleId) {
            battle_id
            message
        }
    }
`

export const GET_BATTLE_REQUESTS = gql`
    query Query($userId: Int!) {
        battleRequests(user_id: $userId) {
            id
            duration
            initiator_id
            initiator {
                username
            }
            opponent_id
            opponent {
                username
            }
            opponent_score
            initiator_score
            start_time
            status
            winner
        }
    }
`
export const GET_BATTLE_HISTORY = gql`
    query Query($userId: Int!) {
        battleHistory(user_id: $userId) {
            id
            initiator_id
            initiator {
                user_id
                username
            }
            initiator_score
            opponent_id
            opponent {
                user_id
                username
            }
            opponent_score
            start_time
            status
            winner
        }
    }

`
export const ADD_BATTLE_TASK = gql`
    mutation Mutation($battleId: Int!, $name: String!, $description: String!, $duration: Int!, $userId: Int!) {
        addBattleTask(battle_id: $battleId, name: $name, description: $description, duration: $duration, user_id: $userId) {
            battle_task_id
            message
        }
    }
`

export const GET_BATTLE_TASKS = gql`
    query Query($battleId: Int!, $userId: Int!) {
        battleTasks(battle_id: $battleId, user_id: $userId) {
            name
            duration
            description
            completed
            id
        }
    }
`
export const COMPLETE_BATTLE_TASK = gql`
    mutation Mutation($taskId: Int!) {
        completeBattleTask(task_id: $taskId) {
            battle_id
            message
        }
    }
`