import React, { useState, useEffect } from 'react'
import { Typography, Box, Container, Stack, Button } from '@mui/material'
import TaskCard from '../components/TaskCard'
import AddTaskIcon from '@mui/icons-material/AddTask';
import CreateTaskModal from '../components/CreateTaskModal';
import { useMutation, useQuery } from '@apollo/react-hooks'
import { GET_USER_DETAILS_BY_ID } from '../graphql/UserSchemas'
import { getUserId } from '../utils/userLogin'
import { GET_TASKS, CREATE_TASK } from '../graphql/TaskSchemas'
import { GET_GOOGLE_AUTH_URL } from '../graphql/CalendarSchemas'
import CalendarConnect from '../components/CalendarConnect';

const HomePage = () => {
    const calendarConnect = CalendarConnect();
    console.log(localStorage.getItem('calendarConnected'));
    const [username, setUsername] = useState([])
    const [tasks, setTasks] = useState([])
    const [openModal, setOpenModal] = useState(false)
    const [isCalendarConnected, setIsCalendarConnected] = useState(localStorage.getItem('calendarConnected') === 'true');
    const { data: authUrlData } = useQuery(GET_GOOGLE_AUTH_URL, {
        variables: { userId: parseInt(getUserId()) }
    });
    const { error: tasksError, loading: tasksLoading, data: tasksData } = useQuery(GET_TASKS, {
        variables: { userId: parseInt(getUserId()) }
    })
    const [createTask, { createTaskloading }] = useMutation(CREATE_TASK);
    const { error, loading, data } = useQuery(GET_USER_DETAILS_BY_ID, {
        variables: { userId: parseInt(getUserId()) }
    });

    useEffect(() => {
        if (data && data.user.username) {
            setUsername(data.user.username);
            console.log(data);
        }
    }, [data]);

    useEffect(() => {
        if (localStorage.getItem('calendarConnected') === 'true') {
            setIsCalendarConnected(true);
        }
    }, []);
    useEffect(() => {
        // Placeholder for API call
        if (tasksData && tasksData.userTasks) {
            console.log(tasksData);
            setTasks(tasksData.userTasks);
        }
    }, [tasksData])


    const handleAddTask = async (taskData) => {
        console.log(taskData);
        try {
            const { data } = await createTask({
                variables: {
                    userId: parseInt(getUserId()),
                    title: taskData.title,
                    dueDate: taskData.due_date,
                    status: taskData.status,
                    description: taskData.description,
                    reminderTime: taskData.reminder_time,
                    tradeReceiverId: 1,
                    tradeStatus: 'none'
                }
            });

            setOpenModal(false);
            window.location.reload();
        } catch (error) {
            console.log("Task creation failed: ", error);
        }
    }


    return (
        <Container maxWidth="lg">
            <Box sx={{ my: 4 }}>
                <Box display={'flex'} justifyContent={'space-between'}>
                    <Typography variant="h4" component="h1" gutterBottom>
                        Welcome, {username}!
                    </Typography>
                    <Button
                        variant='contained'
                        sx={{ borderRadius: 15, backgroundColor: "#1E4759", ml: 53 }}
                        onClick={() => {
                            if (authUrlData?.getGoogleAuthUrl?.auth_url) {
                                window.open(authUrlData.getGoogleAuthUrl.auth_url, '_blank');
                            }
                        }}
                        disabled={isCalendarConnected}
                    >
                        {isCalendarConnected ? 'Connected to Google Calendar' : 'Connect to Google Calendar'}
                    </Button>
                    <Button
                        variant='contained'
                        sx={{ borderRadius: 15, backgroundColor: "#1E4759" }}
                        onClick={() => setOpenModal(true)}
                    >
                        <AddTaskIcon sx={{ mr: 1 }} />
                        Create Task
                    </Button>
                    <CreateTaskModal open={openModal} handleClose={() => setOpenModal(false)} handleAddTask={handleAddTask} />
                </Box>
                <Typography variant="button" component="h1" gutterBottom sx={{ mx: 0.3 }}>
                    Your Tasks
                </Typography>
                <Stack spacing={3}>
                    {tasks.map((task) => (
                        <TaskCard {...task} key={task.task_id}
                            calendarConnected={isCalendarConnected} />
                    ))}
                </Stack>
            </Box>
        </Container>
    )
}

export default HomePage