import React from 'react';
import { AppBar, Toolbar, Typography, Button, Avatar, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import colorLogo from '../assets/logoo.jpg';
import SocialDistanceIcon from '@mui/icons-material/SocialDistance';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import ForestIcon from '@mui/icons-material/Forest';

const Navbar = () => {
  return (
    <AppBar position="static" sx={{ width: '100%', backgroundColor: '#1E4759' }}>
      <Toolbar>
        <Typography variant="h6" component="div" sx={{ flexGrow: 1, marginRight: 4 }}>
          <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
            <Box sx={{ display: 'flex', justifyContent: 'left', alignItems: 'left ', flexDirection: 'row', gap: 1 }}>
              <img src={colorLogo} alt="Logo" style={{ width: 125, height: 40 }} />
            </Box>
          </Link>
        </Typography>
        <Button color="inherit" component={Link} to="/tree" sx={{ marginRight: 5 }}>
          <ForestIcon sx={{ marginRight: 1 }} />
          Tree
        </Button>
        <Button color="inherit" component={Link} to="/trades" sx={{ marginRight: 5 }}>
          <SocialDistanceIcon sx={{ marginRight: 1 }} />
          Trade
        </Button>
        <Button color="inherit" component={Link} to="/battles">
          <SportsKabaddiIcon sx={{ marginRight: 1 }} />
          Battle
        </Button>
        <Avatar sx={{ marginLeft: 5 }}>U</Avatar>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
