import React from 'react';
import { Card, CardContent, Typography, Avatar, Chip, Box, Button, capitalize } from '@mui/material';
import { FormatBold } from '@mui/icons-material';
import CalendarConnect from './CalendarConnect';
import { useMutation } from '@apollo/react-hooks';
import { COMPLETE_TASK } from '../graphql/TaskSchemas';

const TaskCard = (task, calendarConnected) => {
  const [completeTask, { completeTaskLoading }] = useMutation(COMPLETE_TASK);
  const calendarConnect = CalendarConnect();
  const handleSyncTaskToGoogleCalendar = async (taskId) => {
    calendarConnect.syncTaskToGoogleCalendar(taskId);
  }
  
  const handleCompleteTask = async (taskId) => {
    try {
        const data = await completeTask({
            variables: {
                taskId: taskId
            }
        });
        window.location.reload();   
    } catch (error) {
        console.log("Task completion failed: ", error);
    }
}

  return (
    <Card sx={{ minWidth: 700, mb: 2, backgroundColor: '#f5f5f5' }}>
      <CardContent>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6" component="div" gutterBottom>
            {task.title}
          </Typography>
          <Box display="flex" alignItems="center">
            <Button
              variant='contained'
              sx={{ borderRadius: 15, backgroundColor: "#1E4759", mb: 2, mr: 2 }}
              onClick={() => handleCompleteTask(task.task_id)}
            >
              Complete Task
            </Button>
            <Button
              variant='contained'
              sx={{ borderRadius: 15, backgroundColor: "#1E4759", mb: 2 }}
              onClick={() => handleSyncTaskToGoogleCalendar(task.task_id)}
            >
              Sync to Google Calendar
            </Button>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="body2" color="text.secondary" gutterBottom>
            {task.description}
          </Typography>
          <Box display="flex" alignItems="center">
            <Typography variant="body2" color="text.secondary" gutterBottom sx={{ fontWeight: 'bold', mr: 1 }}>
              Due Date:
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom>
              {task.due_date}
            </Typography>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
          <Chip
            data-testid="status-chip"
            label={capitalize(task.status)}
            color={task.status === 'completed' ? 'success' : (task.status === 'scheduled' ? 'warning' : 'secondary')}
            size="small"
          />
          <Box display="flex" alignItems="center">
            <Typography variant="body2" color="text.secondary" sx={{ fontWeight: 'bold', mr: 1 }} gutterBottom>
              Remind at:
            </Typography>
            <Typography variant="body2" color="text.secondary" gutterBottom sx={{ mr: 0.3 }}>
              {task.reminder_time}
            </Typography>
          </Box>
        </Box>
      </CardContent>
    </Card >
  );
};

export default TaskCard;

