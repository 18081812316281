import React, { useState } from 'react';
import { Modal, Box, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const style = {
  position: 'absolute',
  top: '52%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const CreateTaskModal = ({ open, handleClose, handleAddTask }) => {
  const [taskData, setTaskData] = useState({
    title: '',
    description: '',
    status: 'scheduled',
    reminder_time: null,
    due_date: null
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    handleAddTask(taskData);
    handleClose();
  };

  const formatDateTime = (dateTimeStr) => {
    const date = new Date(dateTimeStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};

  return (
    <Modal open={open} onClose={handleClose}>
      <Box sx={style}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Typography variant="h6" component="h2" data-testid="modal-title">
            Create New Task
          </Typography>
          <Button onClick={handleClose} data-testid="close-button" sx={{ minWidth: 'auto', p: 0 }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </Button>
        </Box>
        <form onSubmit={handleSubmit}>
          <TextField
            data-testid="task-title-input"
            fullWidth
            label="Task Title"
            value={taskData.title}
            onChange={(e) => setTaskData({ ...taskData, title: e.target.value })}
            margin="normal"
            required
          />
          <TextField
            data-testid="task-description-input"
            fullWidth
            label="Description"
            value={taskData.description}
            onChange={(e) => setTaskData({ ...taskData, description: e.target.value })}
            margin="normal"
            multiline
            rows={3}
            required
          />
          <FormControl fullWidth margin="normal" required>
            <InputLabel id="status-label">Status</InputLabel>
            <Select
              data-testid="task-status-select"
              value={taskData.status}
              labelId="status-label"
              label="Status"
              onChange={(e) => setTaskData({ ...taskData, status: e.target.value })}
            >
              <MenuItem value="scheduled">Scheduled</MenuItem>
              <MenuItem value="re-scheduled">Re-scheduled</MenuItem>
              <MenuItem value="completed">Completed</MenuItem>
            </Select>
          </FormControl>
          <TextField
            data-testid="reminder-datetime-input"
            size="small"
            value={taskData.reminder_time}
            label="Reminder Date Time"
            variant="outlined"
            type="datetime-local"
            InputLabelProps={{ shrink: true }}
            inputProps={{ step: 1 }}  // This enables seconds selection
            sx={{ width: '200px',mr:2, mt:2 }}
            onChange={(e) => setTaskData({ ...taskData, reminder_time: formatDateTime(e.target.value) })}
          />
          <TextField
            data-testid="due-datetime-input"
            size="small"
            label="Due Date Time"
            value={taskData.due_date}
            variant="outlined"
            type="datetime-local"
            InputLabelProps={{ shrink: true }}
            inputProps={{ step: 1 }}  // This enables seconds selection
            sx={{ width: '183px', mt: 2 }}
            onChange={(e) => setTaskData({ ...taskData, due_date: formatDateTime(e.target.value) })}
            />
          <Button 
            data-testid="submit-button"
            type="submit" 
            variant="contained" 
            sx={{ mt: 2 }} 
            fullWidth
          >
            Create Task
          </Button>
        </form>
      </Box>
    </Modal>
  );
};

export default CreateTaskModal;